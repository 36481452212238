import { useDisclosure, useSnackbarMutations } from '@aignostics/components';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { RelatedWsisModal } from '../../../components/RelatedWsisModal/RelatedWsisModal.component';
import { MatchingWsisGroup } from '../../../hooks/useMatchingWsis';
import {
  type CancerSite,
  type Disease,
  type Morphology,
  type PreparationType,
  type SampleType,
  type Scanner,
  type Staining,
  type Tissue,
} from '../../../types';
import type {
  MultiChannelFormRow,
  SingleChannelFormRow,
} from '../Form/form.state.types';
import FileMetadataFieldset from './FileMetadataFieldset.component';
import { hasMultiChannelErrors } from './FileMetadataFieldset.utils';
import {
  $FieldArrayWrapper,
  $FileMetadataFieldsetList,
} from './FileMetadataFieldsetList.component.styles';

interface FileMetadataFieldsetListProps {
  tissues: Tissue[];
  stainings: Staining[];
  scanners: Scanner[];
  diseases: Disease[];
  preparationTypes: PreparationType[];
  sampleTypes: SampleType[];
  morphologies: Morphology[];
  cancerSites: CancerSite[];
  openStainingsErrorsModal: () => void;
  matchingWsisGroups: MatchingWsisGroup[];
  showSlideRemoveButton: boolean;
  showExtraFields: boolean;
  onToggleChannelsClick: (
    isChannelsOpen: boolean,
    slideFileName: string,
    multiplexSlidesNamesWithVisibleChannels: string[]
  ) => void;
  multiplexSlidesNamesWithVisibleChannels: string[];
}

/** A fieldset with the metadata fields of a slide file to be uploaded */
export const FileMetadataFieldsetList = ({
  tissues,
  stainings,
  scanners,
  diseases,
  preparationTypes,
  sampleTypes,
  morphologies,
  cancerSites,
  showExtraFields,
  openStainingsErrorsModal,
  matchingWsisGroups,
  showSlideRemoveButton,
  onToggleChannelsClick,
  multiplexSlidesNamesWithVisibleChannels,
}: FileMetadataFieldsetListProps): ReactElement => {
  const { addSnackbar } = useSnackbarMutations();
  const [isUserNotifiedAboutRelatedWsis, setIsUserNotifiedAboutRelatedWsis] =
    useState(false);

  const {
    open: openNotifyDialog,
    close: closeNotifyDialog,
    isOpen: isNotifyDialogOpen,
  } = useDisclosure(false);
  const showSlideFileNameWarnings = useCallback(
    (slideFileName) => {
      addSnackbar({
        type: 'success',
        message: `Slide "${slideFileName}" has been removed`,
      });
    },
    [addSnackbar]
  );

  useEffect(() => {
    if (
      !isUserNotifiedAboutRelatedWsis &&
      matchingWsisGroups.some(({ wsis }) => wsis.length > 0)
    ) {
      openNotifyDialog();
    }
  }, [matchingWsisGroups, isUserNotifiedAboutRelatedWsis, openNotifyDialog]);

  return (
    <$FileMetadataFieldsetList>
      <$FieldArrayWrapper>
        <FieldArray<SingleChannelFormRow | MultiChannelFormRow>
          name="slides"
          render={({ fields, meta }) =>
            fields.map((name, fieldIndex) => {
              const field = fields.value[fieldIndex];
              const slideFile =
                field.type === 'single-channel'
                  ? field.slideFile
                  : field.multichannelSlideFile.slideFile;
              const slideFileName = slideFile.filename;

              return (
                <FileMetadataFieldset
                  data-testid={`${slideFileName}-row`}
                  key={`${slideFile.source}:${slideFileName}`}
                  scanners={scanners}
                  field={field}
                  name={name}
                  showExtraFields={showExtraFields}
                  fieldRemove={fields.remove}
                  fieldIndex={fieldIndex}
                  showWarnings={showSlideFileNameWarnings}
                  matchingWsisGroups={matchingWsisGroups}
                  shouldRenderDeleteButton={
                    showSlideRemoveButton && (fields.length ?? 0) > 1
                  }
                  stainings={stainings}
                  tissues={tissues}
                  diseases={diseases}
                  preparationTypes={preparationTypes}
                  sampleTypes={sampleTypes}
                  morphologies={morphologies}
                  cancerSites={cancerSites}
                  openStainingsErrorsModal={openStainingsErrorsModal}
                  channelErrors={
                    field.type === 'multi-channel' &&
                    hasMultiChannelErrors(fieldIndex, meta.error)
                  }
                  onToggleChannelsClick={onToggleChannelsClick}
                  slideFileName={slideFileName}
                  multiplexSlidesNamesWithVisibleChannels={
                    multiplexSlidesNamesWithVisibleChannels
                  }
                  hasChannelsOpen={multiplexSlidesNamesWithVisibleChannels.includes(
                    slideFileName
                  )}
                />
              );
            })
          }
        />
      </$FieldArrayWrapper>
      <RelatedWsisModal
        isVisible={isNotifyDialogOpen}
        onClose={() => {
          setIsUserNotifiedAboutRelatedWsis(true);
          closeNotifyDialog();
        }}
      />
    </$FileMetadataFieldsetList>
  );
};
