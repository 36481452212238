import { CategoryItem, Tooltip } from '@aignostics/components';
import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';
import { useActiveViewerParams } from '../../../__Viewer/ViewerController';
import { StampTool } from '../../Drawing';
import DrawingToolSettings from '../../Drawing/DrawingTools/DrawingToolSettings.component';

interface FocusAreaItemProps {
  active: boolean;
  label: string;
  tool: StampTool;
  onSizeChanged: (newSize: number) => void;
  disabled: boolean;
  isAnnotationFeatureActive: boolean;
  onVisibilityChange: (visible: boolean) => void;
  onActiveChange: (active: boolean) => void;
  isRegionVisible: boolean;
  onMouseUp: (size: number) => void;
  onMouseDown: () => void;
  isViewer: boolean;
}

/**
 * Renders different admin tools
 */
function FocusAreaItem({
  active,
  label,
  tool,
  onSizeChanged,
  disabled,
  isAnnotationFeatureActive,
  onVisibilityChange,
  onActiveChange,
  isRegionVisible,
  onMouseUp,
  onMouseDown,
  isViewer,
}: FocusAreaItemProps): ReactElement {
  const theme = useTheme();
  const { visibleRegions } = useActiveViewerParams();

  const getTooltipText = () => {
    if (!isAnnotationFeatureActive) return 'Annotation Feature is off';
    if (!isRegionVisible) return 'Region is off';
    if (disabled) {
      return 'Resize and Move functions are not available since the region contains annotations.';
    }
    return 'Resize and Move functions for selected region of interest.';
  };

  const handleRegionsVisibility = (visible: boolean) => {
    onVisibilityChange(visible);
  };

  const handleRegionsActive = (active: boolean) => {
    onActiveChange(active);
  };

  const isSliderDisabled =
    disabled ||
    !isAnnotationFeatureActive ||
    (!isRegionVisible && visibleRegions !== null);

  return (
    <CategoryItem
      name={label}
      color={active ? theme.colors.complementary : theme.colors.transparent}
      active={active}
      isVisible={isRegionVisible || !visibleRegions} // if default render all
      setVisible={() => {
        handleRegionsVisibility(!isRegionVisible);
      }}
      isOpenInitial={active}
      setActive={() => {
        handleRegionsActive(true);
      }}
    >
      {!isViewer ? (
        <section aria-labelledby="focus-area-heading">
          <div
            style={{
              padding: `${theme.spacings[8]}px ${theme.spacings[16]}px`,
            }}
          >
            <Tooltip text={getTooltipText()}>
              {(tooltipProps) => (
                <div {...tooltipProps}>
                  <DrawingToolSettings
                    tool={tool}
                    onToolSettingsChanged={(tool) => {
                      onSizeChanged(tool.size);
                    }}
                    disabled={isSliderDisabled}
                    onMouseDown={onMouseDown}
                    onMouseUp={() => {
                      onMouseUp(tool.size);
                    }}
                  />
                </div>
              )}
            </Tooltip>
          </div>
        </section>
      ) : null}
    </CategoryItem>
  );
}

export default FocusAreaItem;
