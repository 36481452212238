import {
  Button,
  HStack,
  Icon,
  Modal,
  Section,
  Text,
  useDisclosure,
  useSnackbarMutations,
  VStack,
} from '@aignostics/components';
import { useMutation } from '@apollo/client';
import React, { ReactElement, useState } from 'react';
import { AnnotationCategoryForm } from '../../../components';
import {
  AnnotationCategory,
  AnnotationCategoryInput,
  AnnotationCategorySet,
  AnnotationCategorySetsQueryVariables,
} from '../../../types';
import { FETCH_ANNOTATION_CATEGORY_SETS } from '../CategorySets';
import { DeleteAnnotationCategoryModal } from './DeleteAnnotationCategoryModal/DeleteAnnotationCategoryModal.component';
import { UPDATE_ANNOTATION_CATEGORY } from './UPDATE_ANNOTATION_CATEGORY';

const CONFIRM_UPDATE_MODAL_MAX_WIDTH = 415;

export const AnnotationCategoriesTableRowActions = ({
  annotationCategory,
  annotationCategorySets,
  allAnnotationCategoryNames,
  onUpdateAnnotationCategory,
  annotationCategorySetsQueryVariables,
}: {
  annotationCategory: AnnotationCategory;
  annotationCategorySets: AnnotationCategorySet[];
  allAnnotationCategoryNames: string[];
  onUpdateAnnotationCategory: () => void;
  annotationCategorySetsQueryVariables: AnnotationCategorySetsQueryVariables | null;
}): ReactElement => {
  const deleteAnnotationCategoryDisclosure = useDisclosure();
  const editAnnotationCategoryDisclosure = useDisclosure();
  const confirmUpdateAnnotationCategoryDisclosure = useDisclosure();

  const [
    updateAnnotationCategoryMutationVariables,
    setUpdateAnnotationCategoryMutationVariables,
  ] = useState<Partial<AnnotationCategoryInput> | null>(null);

  const { addSnackbar } = useSnackbarMutations();

  const [
    updateAnnotationCategory,
    { loading: updateAnnotationCategoryLoading },
  ] = useMutation(UPDATE_ANNOTATION_CATEGORY, {
    refetchQueries: [
      {
        query: FETCH_ANNOTATION_CATEGORY_SETS,
        ...(annotationCategorySetsQueryVariables
          ? { variables: annotationCategorySetsQueryVariables }
          : null),
      },
    ],
    onCompleted: () => {
      addSnackbar({
        type: 'success',
        message: 'Annotation category was successfully updated.',
      });
      editAnnotationCategoryDisclosure.close();
      onUpdateAnnotationCategory();
    },
    onError: (error) => {
      addSnackbar({
        type: 'error',
        message: error.message,
      });
    },
  });

  const handleUpdateAnnotationCategory = async ({
    name,
    color,
    id,
    annotationCategorySet,
  }: AnnotationCategoryInput) => {
    const updatedAnnotationCategory = {
      name,
      color,
      id,
      annotationCategorySet,
    };
    if (name !== annotationCategory.name) {
      setUpdateAnnotationCategoryMutationVariables(updatedAnnotationCategory);
      confirmUpdateAnnotationCategoryDisclosure.open();
    } else {
      await updateAnnotationCategory({
        variables: {
          annotationCategory: updatedAnnotationCategory,
        },
      });
    }
  };

  return (
    <>
      <HStack spacing="16">
        <Button
          onClick={editAnnotationCategoryDisclosure.open}
          small
          variant="ghost"
        >
          <Icon icon="Edit" />
        </Button>
        <Button
          small
          variant="ghost"
          onClick={deleteAnnotationCategoryDisclosure.open}
        >
          <Icon icon="Trash" />
        </Button>
      </HStack>
      {deleteAnnotationCategoryDisclosure.isOpen ? (
        <DeleteAnnotationCategoryModal
          onDeleteAnnotationCategory={() => {
            onUpdateAnnotationCategory();
            deleteAnnotationCategoryDisclosure.close();
          }}
          onClose={deleteAnnotationCategoryDisclosure.close}
          annotationCategoryId={annotationCategory.id}
        />
      ) : null}
      {editAnnotationCategoryDisclosure.isOpen ? (
        <>
          <Modal
            onClose={editAnnotationCategoryDisclosure.close}
            isVisible={editAnnotationCategoryDisclosure.isOpen}
            shouldCloseOnEscKey
            hasCloseButton
          >
            <AnnotationCategoryForm
              initialValues={annotationCategory}
              mode="edit"
              onSubmit={handleUpdateAnnotationCategory}
              annotationCategorySets={annotationCategorySets}
              annotationCategoryNames={allAnnotationCategoryNames}
              loading={updateAnnotationCategoryLoading}
            />
          </Modal>
          <Modal
            onClose={confirmUpdateAnnotationCategoryDisclosure.close}
            isVisible={confirmUpdateAnnotationCategoryDisclosure.isOpen}
            hasCloseButton
          >
            <Section
              loading={updateAnnotationCategoryLoading}
              style={{ maxWidth: `${CONFIRM_UPDATE_MODAL_MAX_WIDTH}px` }}
            >
              <VStack alignItems="center" spacing="16">
                <Text as="h2" fontStyle="displayBold">
                  Category name change
                </Text>
                <Text fontStyle="small" style={{ textAlign: 'center' }}>
                  The category name change will affect at least one subproject.
                  Are you sure?
                </Text>
                <HStack spacing="16">
                  <Button
                    variant="primary"
                    onClick={async () => {
                      confirmUpdateAnnotationCategoryDisclosure.close();
                      await updateAnnotationCategory({
                        variables: {
                          annotationCategory:
                            updateAnnotationCategoryMutationVariables,
                        },
                      });
                    }}
                  >
                    Confirm change
                  </Button>
                  <Button
                    variant="primaryOutline"
                    onClick={() => {
                      confirmUpdateAnnotationCategoryDisclosure.close();
                    }}
                  >
                    Cancel
                  </Button>
                </HStack>
              </VStack>
            </Section>
          </Modal>
        </>
      ) : null}
    </>
  );
};
