import {
  Placeholder,
  TableComponent,
  TableHeaderType,
  TableSkeleton,
} from '@aignostics/components';
import React, { ReactElement, useState } from 'react';
import { SortBy, SortByDirection } from '../../../hooks';
import {
  AnnotationCategoriesSortByOptions,
  AnnotationCategory,
  AnnotationCategorySet,
  AnnotationCategorySetsQueryVariables,
} from '../../../types';
import { $TableContainer } from '../AnnotationSettings.component.style';
import { PAGE_SIZE } from './AnnotationCategories.component';
import { AnnotationCategoriesTableRowActions } from './AnnotationCategoriesTableRowActions.component';

export interface AnnotationCategoriesTableProps {
  loading: boolean;
  annotationCategories: AnnotationCategory[];
  annotationCategorySets: null | AnnotationCategorySet[];
  allAnnotationCategoryNames: string[];
  sortBy: SortBy<AnnotationCategoriesSortByOptions>;
  setSortByOptions: (sortBy: SortBy<AnnotationCategoriesSortByOptions>) => void;
  onUpdate: () => void;
  annotationCategorySetsQueryVariables: AnnotationCategorySetsQueryVariables | null;
}

const NAME_COLOUMN_LEFT_PADDING = 42;

export const AnnotationCategoriesTable = ({
  loading,
  annotationCategories,
  allAnnotationCategoryNames,
  annotationCategorySets,
  sortBy,
  setSortByOptions,
  onUpdate,
  annotationCategorySetsQueryVariables,
}: AnnotationCategoriesTableProps): ReactElement => {
  const columns: TableHeaderType<AnnotationCategory>[] = [
    {
      id: 'name',
      label: 'Category Name',
      minWidth: '100px',
      renderCell: (row) => (
        <div style={{ paddingLeft: `${NAME_COLOUMN_LEFT_PADDING}px` }}>
          {row?.name}
        </div>
      ),
      align: 'left',
    },
    {
      id: 'color',
      label: 'Category Color',
      minWidth: '200px',
      renderCell: (row) => (
        <input type="color" disabled value={`#${row.color.replace('#', '')}`} />
      ),
      align: 'left',
    },
    {
      id: 'setCode',
      label: 'Set Identifier',
      renderCell: (row) => row?.setCode ?? '--',
      align: 'left',
    },
    {
      id: 'setName',
      label: 'Annotation Set',
      renderCell: (row) => row?.setName ?? '--',
      align: 'left',
    },
    {
      id: 'setModule',
      label: 'Module',
      renderCell: (row) => row?.setModule ?? '--',
      align: 'left',
    },
    {
      id: 'actions',
      label: 'Actions',
      disableSorting: true,
      renderCell: (row) => (
        <AnnotationCategoriesTableRowActions
          onUpdateAnnotationCategory={onUpdate}
          annotationCategory={row}
          allAnnotationCategoryNames={allAnnotationCategoryNames}
          annotationCategorySets={annotationCategorySets ?? []}
          annotationCategorySetsQueryVariables={
            annotationCategorySetsQueryVariables
          }
        />
      ),
      align: 'right',
    },
  ];
  const [columnsState, setColumnsState] = useState<
    TableHeaderType<AnnotationCategory>[]
  >([...columns.map((column) => ({ ...column, checked: true }))]);

  return (
    <$TableContainer>
      {annotationCategorySets !== null &&
      (loading || (!loading && annotationCategories.length)) ? (
        <TableComponent<AnnotationCategory>
          dynamicCoulmns={false}
          enableSelection={false}
          data={loading || !annotationCategories ? [] : annotationCategories}
          columns={columnsState}
          setColumnsState={setColumnsState}
          rowSelector="id"
          sorting={{
            column: sortBy?.column ?? 'name',
            direction: sortBy?.sortDirection ?? 'asc',
          }}
          setSorting={(val) => {
            setSortByOptions({
              column: val?.column as AnnotationCategoriesSortByOptions,
              sortDirection: val?.direction as SortByDirection,
            });
          }}
        />
      ) : null}
      {!loading && annotationCategories.length === 0 ? (
        <Placeholder title="No categories found" />
      ) : null}
      {loading && (
        <div data-testid="annotation-categories-table-skeleton">
          <TableSkeleton rows={PAGE_SIZE} />
        </div>
      )}
    </$TableContainer>
  );
};
