import {
  Button,
  HStack,
  Modal,
  Section,
  useSnackbarMutations,
  VStack,
} from '@aignostics/components';
import { useMutation } from '@apollo/client';
import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';
import { DELETE_ANNOTATION_CATEGORY_SET } from './DELETE_ANNOTATION_CATEGORY_SET';
import {
  $ModalContent,
  $ModalHeader,
} from './DeleteAnnotationCategorySetModal.component.styles';

export const DeleteAnnotationCategorySetModal = ({
  onDeleteAnnotationCategorySet,
  onClose,
  annotationCategorySetId,
}: {
  onDeleteAnnotationCategorySet: () => void;
  onClose: () => void;
  annotationCategorySetId: string;
}): ReactElement => {
  const theme = useTheme();
  const { addSnackbar } = useSnackbarMutations();

  const [
    deleteAnnotationCategorySet,
    { loading: deleteAnnotationCategorySetLoading },
  ] = useMutation<{
    deleteAnnotationCategorySet: string;
  }>(DELETE_ANNOTATION_CATEGORY_SET, {
    onError: (error) => {
      addSnackbar({
        type: 'error',
        message: error.message,
      });
    },
    onCompleted: (data) => {
      addSnackbar({
        type: 'success',
        message: data.deleteAnnotationCategorySet,
        closesAfter: 1000,
      });
      onDeleteAnnotationCategorySet();
    },
  });

  return (
    <Modal isVisible={true} onClose={onClose} size="small">
      <Section
        loading={deleteAnnotationCategorySetLoading}
        style={{ padding: `${theme.spacings[32]}px` }}
      >
        <VStack spacing="32">
          <VStack spacing="16" style={{ width: '100%' }}>
            <$ModalHeader>Delete Annotation Set</$ModalHeader>
            <$ModalContent>
              You are about to delete an annotation set.
              <br />
              This action is not reversible.
              <br />
              Are you sure you want to do that?
            </$ModalContent>
          </VStack>
          <HStack
            style={{ width: '100%' }}
            spacing="16"
            justifyContent="center"
          >
            <Button
              onClick={async () => {
                await deleteAnnotationCategorySet({
                  variables: {
                    annotationCategorySetId,
                  },
                });
              }}
              variant="primary"
            >
              Yes Continue
            </Button>
            <Button onClick={onClose} variant="primaryOutline">
              No, Cancel
            </Button>
          </HStack>
        </VStack>
      </Section>
    </Modal>
  );
};
