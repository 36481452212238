import { useSnackbarMutations } from '@aignostics/components';
import { ApolloError, useMutation } from '@apollo/client';
import { AnnotationCategorySet } from '../../../../types';
import { CREATE_ANNOTATION_CATEGORY_SET_MODULES } from './CREATE_ANNOTATION_CATEGORY_SET_MODULES';
import { DELETE_ANNOTATION_CATEGORY_SET_MODULES } from './DELETE_ANNOTATION_CATEGORY_SET_MODULES';
import { UPDATE_ANNOTATION_CATEGORY_SET } from './UPDATE_ANNOTATION_CATEGORY_SET';
import { UPDATE_ANNOTATION_CATEGORY_SET_MODULES } from './UPDATE_ANNOTATION_CATEGORY_SET_MODULES';
import { getAnnotationCategorySetModuleChanges } from './getAnnotationCategorySetModuleChanges';

export const useUpdateAnnotationCategorySet = (
  onUpdateComplete: () => void
): {
  loading: boolean;
  handleUpdateAnnotationCategorySet: (
    currentAnnotationCategorySet: AnnotationCategorySet,
    updatedAnnotationCategorySet: AnnotationCategorySet
  ) => void;
} => {
  const { addSnackbar } = useSnackbarMutations();

  const [
    updateAnnotationCategorySet,
    { loading: updateAnnotationCategorySetLoading },
  ] = useMutation(UPDATE_ANNOTATION_CATEGORY_SET, {
    onError: (error: ApolloError) => {
      addSnackbar({
        type: 'error',
        message: error.message,
      });
    },
  });

  const [
    createAnnotationCategorySetModules,
    { loading: createAnnotationCategorySetModulesLoading },
  ] = useMutation(CREATE_ANNOTATION_CATEGORY_SET_MODULES, {
    onError: (error: ApolloError) => {
      addSnackbar({
        type: 'error',
        message: error.message,
      });
    },
  });

  const [
    deleteAnnotationCategorySetModules,
    { loading: deleteAnnotationCategorySetModulesLoading },
  ] = useMutation(DELETE_ANNOTATION_CATEGORY_SET_MODULES, {
    onError: (error: ApolloError) => {
      addSnackbar({
        type: 'error',
        message: error.message,
      });
    },
  });

  const [
    updateAnnotationCategorySetModules,
    { loading: updateAnnotationCategorySetModulesLoading },
  ] = useMutation(UPDATE_ANNOTATION_CATEGORY_SET_MODULES, {
    onError: (error: ApolloError) => {
      addSnackbar({
        type: 'error',
        message: error.message,
      });
    },
  });

  const handleUpdateAnnotationCategorySet = async (
    currentAnnotationCategorySet: AnnotationCategorySet,
    updatedAnnotationCategorySet: AnnotationCategorySet
  ) => {
    const { id, name, code, modules } = updatedAnnotationCategorySet;
    const { create, remove, update } = getAnnotationCategorySetModuleChanges(
      currentAnnotationCategorySet.modules,
      modules
    );

    await updateAnnotationCategorySet({
      variables: {
        annotationCategorySet: { id, code, name },
      },
    });

    if (create.length) {
      await createAnnotationCategorySetModules({
        variables: {
          annotationCategorySetId: id,
          modules: create,
        },
      });
    }

    if (remove.length) {
      await deleteAnnotationCategorySetModules({
        variables: {
          annotationCategorySetId: id,
          modules: remove,
        },
      });
    }

    if (update.length) {
      await updateAnnotationCategorySetModules({
        variables: {
          annotationCategorySetId: id,
          modules: update,
        },
      });
    }
    onUpdateComplete();
  };

  return {
    loading:
      updateAnnotationCategorySetLoading ||
      createAnnotationCategorySetModulesLoading ||
      deleteAnnotationCategorySetModulesLoading ||
      updateAnnotationCategorySetModulesLoading,
    handleUpdateAnnotationCategorySet,
  };
};
