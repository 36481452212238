import { isTouchDevice } from '@aignostics/components';
import { Feature, Map, MapBrowserEvent } from 'ol';
import { Geometry } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Stroke, Style } from 'ol/style';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { usePointerOverlay } from '../../../utils';
import LayerBaseComponent from '../../Layer.Base.component';
import { getHoveredFeature } from '../getHoveredFeature';
import { CrosshairIcon } from './CrosshairIcon';

export interface PickerLayerProps {
  color: string;
  zIndex: number;
  onFeatureClick: (feature: Feature<Geometry>) => void;
  map: Map;
}

/**
 * Layer to create `Annotations` from `InteractiveOverlays` by clicking on the
 * features.
 */
function PickerLayer({
  color,
  zIndex,
  onFeatureClick,
  map,
}: PickerLayerProps): ReactElement {
  const layer = useMemo(() => {
    const initSource = new VectorSource();

    const vectorLayer = new VectorLayer({
      source: initSource,
      zIndex,
    });
    return vectorLayer;
  }, [zIndex]);

  // Currently hovered feature
  const [hoveredFeature, setHoveredFeature] =
    useState<Feature<Geometry> | null>(null);
  const icon = useMemo(() => <CrosshairIcon />, []);

  usePointerOverlay(map, { icon });

  const handlePointerMove = useCallback(
    (event: MapBrowserEvent<PointerEvent>) => {
      const currentHoveredFeature = getHoveredFeature(map, event.pixel);
      if (currentHoveredFeature !== null) {
        setHoveredFeature((previousHoveredFeature) => {
          if (previousHoveredFeature !== null) {
            const featureChanged =
              previousHoveredFeature.get('polygon_id') !==
              currentHoveredFeature.get('polygon_id');
            if (!featureChanged) return previousHoveredFeature;
          }

          return currentHoveredFeature;
        });
      } else {
        setHoveredFeature(null);
      }
    },
    [map]
  );

  /**
   * Create, delete or update annotation based on underlying interactive overlay
   *
   * @param event
   */
  const handleClick = useCallback(
    (event: MapBrowserEvent<PointerEvent>) => {
      const hoveredFeature = getHoveredFeature(map, event.pixel);

      if (hoveredFeature === null) return;
      onFeatureClick(hoveredFeature.clone());
    },
    [map, onFeatureClick]
  );

  useEffect(() => {
    const source = layer.getSource();
    if (source === null) throw new Error('PickerLayer needs a source');

    source.clear();
    if (hoveredFeature !== null) source.addFeatures([hoveredFeature]);
    layer.setSource(source);

    layer?.setStyle(getStyle(color));
  }, [color, hoveredFeature, layer, zIndex]);

  // Attach event listeners to map object
  useEffect(() => {
    // Only add hover effect for non touch device
    if (!isTouchDevice()) {
      map.on('pointermove', handlePointerMove);
    }

    // ol typings for event types are wrong
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    map.on('pointerdown' as any, handleClick);

    // Detach on unmount
    return () => {
      if (!isTouchDevice()) {
        map.un('pointermove', handlePointerMove);
      }

      // ol typings for event types are wrong
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map.un('pointerdown' as any, handleClick);
    };
  }, [map, handleClick, handlePointerMove]);

  return (
    <LayerBaseComponent layer={layer} zIndex={zIndex} opacity={1} map={map} />
  );
}

export default PickerLayer;

function getStyle(color: string): Style {
  return new Style({
    stroke: new Stroke({ color }),
  });
}
