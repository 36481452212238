import { useSnackbarMutations } from '@aignostics/components';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { Polygon } from 'geojson';
import { Feature, Map as OLMap } from 'ol';
import { Geometry } from 'ol/geom';
import React, { ReactElement, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  AnnotationCategory,
  AnnotationFeatureType,
  AnnotationProperties,
  OtherAnnotationVisibility,
} from '../../../../../api-types';

import { OrganizationRole, User } from '@aignostics/core';
import { addAnnotationToCache } from '../../../../../graphql/helpers';
import FETCH_POLYGON_GEOMETRY from '../../../../../graphql/queries/FETCH_POLYGON_GEOMETRY';
import {
  canEditOrDeleteFeature,
  deleteAnnotation,
  updateAnnotationCategory,
} from '../../../../__Features/Annotations/utils';
import { AnnotationDrawingMode } from '../../../../__Features/Drawing/DrawingMode';
import { createAnnotation } from '../../utils';
import BrushLayer from './Brush/Brush.layer';
import PenLayer from './Pen/Pen.layer';
import PickerLayer from './Picker/Picker.layer';
import { simplifyPenPolygon } from './simplifyPenPolygon';

interface AnnotationDrawingLayerProps {
  map: OLMap;
  width: number;
  height: number;
  maxZoom: number;
  zIndex: number;
  otherAnnotationVisibility: OtherAnnotationVisibility;
  drawingMode: Omit<AnnotationDrawingMode, 'mode'>;
  annotationFeature: AnnotationFeatureType;
  currentUser: User;
  currentUserRole: OrganizationRole;
}

/**
 * Returns the correct drawing tool layer for the given drawing mode
 */
const AnnotationDrawingLayer = ({
  width,
  height,
  zIndex,
  maxZoom,
  map,
  otherAnnotationVisibility,
  drawingMode,
  annotationFeature,
  currentUser,
  currentUserRole,
}: AnnotationDrawingLayerProps): ReactElement | null => {
  const { subProjectId, wsiId } = useParams() as {
    wsiId: string;
    subProjectId: string;
  };
  const [getPolygonGeometry] = useLazyQuery(FETCH_POLYGON_GEOMETRY);
  const { addSnackbar } = useSnackbarMutations();
  const client = useApolloClient();

  const addAnnotation = useCallback(
    (
      polygon: Polygon,
      category: AnnotationCategory,
      properties?: Partial<AnnotationProperties>
    ) => {
      const annotation = createAnnotation(
        polygon,
        currentUser,
        category,
        properties
      );
      if (subProjectId) {
        addAnnotationToCache(client, wsiId, subProjectId, annotation);
      }
    },
    [client, currentUser, subProjectId, wsiId]
  );

  /**
   * Select the appropriate action to be performed once the drawing
   * is done.
   *
   * @param geometry: the drawn shape
   * @returns
   */
  const handlePolygonDrawn = useCallback(
    (polygon: Polygon) => {
      const simplifiedPolygon = simplifyPenPolygon(polygon);

      // Error simplifying, discard annotation.
      if (simplifiedPolygon === undefined) {
        addSnackbar({ type: 'error', message: 'Error creating annotation' });
        return;
      }

      addAnnotation(simplifiedPolygon, drawingMode.category);
    },
    [addAnnotation, addSnackbar, drawingMode.category]
  );

  const handleFeaturePicked = useCallback(
    async (feature: Feature<Geometry>): Promise<void> => {
      // If the feature is a interactive overlay, create annotation out of it
      if (isInteractiveOverlay(feature)) {
        const taggerId = feature.get('tagger');
        const polygonId = feature.get('polygon_id');

        const {
          data,
          loading: geometryLoading,
          error: geometryError,
        } = await getPolygonGeometry({
          variables: { wsiId, taggerId, polygonId },
        });

        if (geometryLoading) return;

        if (geometryError) {
          addSnackbar({
            type: 'error',
            message: 'Error picking cell geometry',
          });
          return;
        }

        if (data) {
          const coordinates = data.polygonGeometry.geometry.coordinates;

          addAnnotation(
            {
              type: 'Polygon',
              coordinates,
            },
            drawingMode.category,
            { origin: feature.get('polygon_id') }
          );
        }
      } else {
        // Only proceed if the annotation is coming from an interactive overlay,
        // meaning the feature has both a category and an origin set
        if (
          !isAnnotationFromInteractiveOverlay(feature) ||
          !canEditOrDeleteFeature({
            currentUserRole: currentUserRole.scopes,
            feature,
            otherAnnotationVisibility,
            currentUser,
            annotationFeature,
          })
        ) {
          return;
        }
        const featureId = feature.get('id');
        const featureCategory = feature.get('category');
        const currentCategory = drawingMode.category;
        const state = feature.get('state');

        // If the currently selected category differs from the feature's one,
        // update the feature's category accordingly
        if (currentCategory && featureCategory.id !== currentCategory.id) {
          updateAnnotationCategory(
            client,
            state,
            featureId,
            currentCategory.id
          );
        }

        // Else, remove the annotation
        else {
          deleteAnnotation(client, state, featureId, wsiId);
        }
      }
    },
    [
      addAnnotation,
      annotationFeature,
      client,
      currentUser,
      currentUserRole.scopes,
      drawingMode.category,
      getPolygonGeometry,
      otherAnnotationVisibility,
      wsiId,
      addSnackbar,
    ]
  );

  switch (drawingMode.tool.name) {
    case 'brush':
      return (
        <BrushLayer
          color={drawingMode.category.color}
          size={drawingMode.tool.size}
          width={width}
          height={height}
          maxZoom={maxZoom}
          onDrawingDone={handlePolygonDrawn}
          zIndex={zIndex}
          map={map}
        />
      );
    case 'pen':
      return (
        <PenLayer
          width={width}
          height={height}
          maxZoom={maxZoom}
          onDrawingDone={handlePolygonDrawn}
          zIndex={zIndex}
          map={map}
        />
      );
    case 'picker':
      return (
        <PickerLayer
          color={drawingMode.category.color}
          onFeatureClick={handleFeaturePicked}
          zIndex={zIndex}
          map={map}
        />
      );
  }
};

export default AnnotationDrawingLayer;

/**
 * Check whether a feature is a interactive overlay
 * @param feature
 * @returns       boolean
 */
const isInteractiveOverlay = (feature: Feature<Geometry>) =>
  feature.get('tagger') !== undefined && feature.get('tagger') !== null;

/**
 * Check whether a feature is annotation made from a interactive overlay
 *
 * @param feature
 * @returns       boolean
 */
const isAnnotationFromInteractiveOverlay = (feature: Feature<Geometry>) =>
  feature.get('category') !== null &&
  feature.get('category') !== undefined &&
  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  feature.get('origin') !== null &&
  feature.get('origin') !== undefined;
