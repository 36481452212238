import {
  Button,
  Filter,
  Pagination,
  PaginationInfo,
  getFiltersFromQueryParams,
  getPageFromQueryParams,
  useDisclosure,
  useFilters,
  usePagination,
  useSnackbarMutations,
} from '@aignostics/components';
import { FilterConfigs } from '@aignostics/onboarding-ui/src/hooks/useFilters';
import { pluralize } from '@aignostics/utils';
import { useMutation, useQuery } from '@apollo/client';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  CREATE_ANNOTATION_CATEGORIES,
  CreateAnnotationCategoryModal,
} from '../../../components';
import {} from '../../../components/AnnotationCategoryForm/CREATE_ANNOTATION_CATEGORY.queries';
import {
  SortBy,
  SortByDirection,
  useSetQueryParams,
  useSortBy,
} from '../../../hooks';
import {
  AnnotationCategoriesFilterOptions,
  AnnotationCategoriesSortByOptions,
  AnnotationCategory,
  AnnotationCategoryInput,
  AnnotationCategorySet,
  AnnotationCategorySetsQueryVariables,
} from '../../../types';
import { getQueryParams } from '../../../utils';
import {
  getAnnotationCategoriesFilters,
  getFilterOptions,
} from '../../SubProject/Admin/AdminSubprojectAnnotations/AdminSubprojectCategories/utils';
import {
  $PageHeaderWrapper,
  $PaginationContainer,
} from '../AnnotationSettings.component.style';
import { FETCH_ANNOTATION_CATEGORY_SETS } from '../CategorySets';
import { AnnotationCategoriesTable } from './AnnotationCategoriesTable.component';
import { FETCH_ANNOTATION_CATEGORIES } from './FETCH_ANNOTATION_CATEGORIES';
import { FETCH_ANNOTATION_CATEGORIES_FILTER_DATA } from './FETCH_ANNOTATION_CATEGORIES_FILTER_DATA';

export const PAGE_SIZE = 20;

const $FiltersWrapper = styled.div`
  padding-left: ${({ theme }) => `${theme.spacings[32]}px`};
  padding-right: ${({ theme }) => `${theme.spacings[32]}px`};
`;

type FilterKeys = 'search' | 'filterSetName' | 'filterSetCode' | 'filterModule';

const PAGE_FILTER_CONFIGS: FilterConfigs<FilterKeys> = {
  search: { fallbackValue: '', type: 'string' },
  filterSetCode: { fallbackValue: '', type: 'string' },
  filterSetName: { fallbackValue: '', type: 'string' },
  filterModule: { fallbackValue: '', type: 'string' },
};

export const AnnotationCategories = ({
  annotationCategorySetsQueryVariables,
}: {
  annotationCategorySetsQueryVariables: AnnotationCategorySetsQueryVariables | null;
}): ReactElement => {
  const [page, setPage] = usePagination(getPageFromQueryParams());

  const [filterOptions, setFilterOptions] =
    useState<AnnotationCategoriesFilterOptions>({
      setNames: [],
      setCodes: [],
      modules: [],
    });

  const urlParams = getQueryParams(['sortBy', 'sortDirection']);
  const sortByParam = urlParams?.sortBy as AnnotationCategoriesSortByOptions;
  const sortDirectionParam = urlParams?.sortDirection as SortByDirection;

  const { sortBy, setSortByOptions } =
    useSortBy<AnnotationCategoriesSortByOptions>({
      column: sortByParam ?? 'name',
      sortDirection: sortDirectionParam || 'asc',
    });

  const { filterModule, filterSetCode, filterSetName, search } =
    getAnnotationCategoriesFilters(filterOptions);

  const PAGE_FILTER_FIELDS = {
    search,
    filterSetName,
    filterSetCode,
    filterModule,
  };

  const { filters, filterProps } = useFilters(
    PAGE_FILTER_CONFIGS,
    getFiltersFromQueryParams(PAGE_FILTER_CONFIGS)
  );

  const queryParams = useMemo(
    () => ({
      page: page.toString(),
      ...filters,
      ...(sortBy?.column ? { sortBy: sortBy.column } : {}),
      ...(sortBy?.sortDirection ? { sortDirection: sortBy.sortDirection } : {}),
    }),
    [page, filters, sortBy?.sortDirection, sortBy?.column]
  );

  useSetQueryParams(queryParams);

  const {
    data: annotationCategorySetsData,
    loading: annotationCategorySetsFilterDataLoading,
  } = useQuery<{
    annotationCategorySets: {
      nodes: AnnotationCategorySet[];
    };
  }>(FETCH_ANNOTATION_CATEGORIES_FILTER_DATA, {
    onCompleted: (data) => {
      if (data) {
        if (filters.filterSetName || filters.filterSetCode) {
          setFilterOptions(
            getFilterOptions({
              annotationCategorySets: data.annotationCategorySets.nodes,
              selectedSetCode: filters.filterSetCode as string,
              selectedSetName: filters.filterSetName as string,
            })
          );
        } else {
          setFilterOptions(
            getFilterOptions({
              annotationCategorySets: data.annotationCategorySets.nodes,
            })
          );
        }
      }
    },
  });

  const annotationCategorySets = useMemo(() => {
    return annotationCategorySetsData?.annotationCategorySets.nodes ?? [];
  }, [annotationCategorySetsData]);

  useEffect(() => {
    if (annotationCategorySets) {
      if (filters.filterSetName || filters.filterSetCode) {
        setFilterOptions(
          getFilterOptions({
            annotationCategorySets,
            selectedSetCode: filters.filterSetCode as string,
            selectedSetName: filters.filterSetName as string,
          })
        );
      } else {
        setFilterOptions(getFilterOptions({ annotationCategorySets }));
      }
    }
  }, [
    annotationCategorySets,
    setFilterOptions,
    filters.filterSetName,
    filters.filterSetCode,
  ]);

  const fetchAnnotationCategoriesQueryVariables = {
    page,
    pageSize: PAGE_SIZE,
    search: filters.search,
    setName: filters.filterSetName,
    setCode: filters.filterSetCode,
    setModule: filters.filterModule,
    sortBy: sortBy?.column,
    sortDirection: sortBy?.sortDirection,
  };

  const {
    data,
    loading: annotationCategoriesLoading,
    refetch,
  } = useQuery<{
    annotationCategories: {
      nodes: AnnotationCategory[];
      pageInfo: {
        totalPages: number;
        totalElements: number;
      };
      collectionAttributes: {
        allAnnotationCategoryNames: string[];
      };
    };
  }>(FETCH_ANNOTATION_CATEGORIES, {
    variables: fetchAnnotationCategoriesQueryVariables,
  });

  const { addSnackbar } = useSnackbarMutations();

  const [
    createAnnotationCategories,
    { loading: createAnnotationCategoriesLoading },
  ] = useMutation(CREATE_ANNOTATION_CATEGORIES, {
    onError: (error) => {
      addSnackbar({
        type: 'error',
        message: error.message,
      });
    },
    onCompleted: () => {
      addSnackbar({
        type: 'success',
        message: 'Annotation category was successfully created!',
      });
    },
    refetchQueries: [
      {
        query: FETCH_ANNOTATION_CATEGORIES,
        variables: fetchAnnotationCategoriesQueryVariables,
      },
      {
        query: FETCH_ANNOTATION_CATEGORIES_FILTER_DATA,
      },
      {
        query: FETCH_ANNOTATION_CATEGORY_SETS,
        ...(annotationCategorySetsQueryVariables
          ? { variables: annotationCategorySetsQueryVariables }
          : null),
      },
    ],
  });

  const { open, close, isOpen } = useDisclosure();

  const handleCreateAnnotationCategory = async (
    newAnnotationCategory: AnnotationCategoryInput
  ) => {
    const { name, annotationCategorySet, color, id } = newAnnotationCategory;

    await createAnnotationCategories({
      variables: {
        annotationCategories: [
          {
            name,
            color,
            id,
            ...(annotationCategorySet?.kind !== 'unset'
              ? { annotationCategorySet }
              : null),
          },
        ],
      },
    });
    close();
  };

  const annotationCategories = data?.annotationCategories.nodes ?? [];
  const pageInfo = data?.annotationCategories.pageInfo;
  const allAnnotationCategoriesNames =
    data?.annotationCategories.collectionAttributes.allAnnotationCategoryNames;

  const loading =
    annotationCategoriesLoading ||
    createAnnotationCategoriesLoading ||
    annotationCategorySetsFilterDataLoading;

  return (
    <>
      <$FiltersWrapper>
        <Filter
          isOpen={false}
          fields={PAGE_FILTER_FIELDS}
          {...filterProps}
          onChange={(filters) => {
            filterProps.onChange(filters);
            setPage(1);
          }}
          onReset={() => {
            filterProps.onReset();
            setPage(1);
          }}
        />
      </$FiltersWrapper>
      <$PageHeaderWrapper>
        <PaginationInfo
          currentPage={page}
          itemsPerPage={PAGE_SIZE}
          totalCount={pageInfo?.totalElements ?? 0}
          itemType={pluralize(
            'Category',
            annotationCategories.length,
            'Categories'
          )}
          loading={loading}
        />
        <Button onClick={open} name="Create Set" small icon="PlusCircle">
          Create Category
        </Button>
      </$PageHeaderWrapper>
      <AnnotationCategoriesTable
        allAnnotationCategoryNames={allAnnotationCategoriesNames ?? []}
        annotationCategorySets={
          !annotationCategorySetsData ? null : annotationCategorySets
        }
        loading={loading}
        annotationCategories={annotationCategories}
        sortBy={sortBy}
        setSortByOptions={(
          sortBy: SortBy<AnnotationCategoriesSortByOptions>
        ) => {
          setSortByOptions(
            sortBy?.column as AnnotationCategoriesSortByOptions,
            sortBy?.sortDirection as SortByDirection
          );
        }}
        onUpdate={refetch}
        annotationCategorySetsQueryVariables={
          annotationCategorySetsQueryVariables
        }
      />
      {pageInfo && pageInfo.totalPages > 1 && (
        <$PaginationContainer>
          <Pagination
            currentPage={page}
            onPageChanged={setPage}
            totalPages={pageInfo.totalPages}
          />
        </$PaginationContainer>
      )}
      <CreateAnnotationCategoryModal
        isOpen={isOpen}
        onClose={close}
        onCreateAnnotationCategory={handleCreateAnnotationCategory}
        annotationCategorySets={annotationCategorySets ?? []}
        annotationCategoryNames={allAnnotationCategoriesNames ?? []}
        loading={createAnnotationCategoriesLoading}
      />
    </>
  );
};
