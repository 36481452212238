import {
  Button,
  Divider,
  Tooltip,
  useSnackbarMutations,
} from '@aignostics/components';
import { useApolloClient } from '@apollo/client';
import { compact } from 'lodash';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { FocusArea } from '../../../../api-types';
import { resizeFromCenter } from '../../../__Viewer/OpenLayers/utils';
import { SlideRouteParams } from '../../../__Viewer/Slide';
import {
  useActiveViewerParams,
  useSetActiveViewerParams,
  useViewerController,
} from '../../../__Viewer/ViewerController';
import { StampTool, useDrawingModeActions } from '../../Drawing';
import { useDrawingToolSettingsStore } from '../../Drawing/useDrawingToolSettingsStore';
import { flyToFeatureInspection } from '../../__FeatureBar/FeatureInspection/flyTo';
import AnnotationSectionItemsNavigation from '../categories/AnnotationNavigation.component';
import FocusAreaItem from './FocusAreaItem.component';
import { useRegionsSettingsStore } from './useRegionsSettingsStore';

interface FocusAreaSectionProps {
  regionsOfInterest: FocusArea[];
  isAnnotationFeatureActive: boolean;
  onVisibilityChange: () => void;
  height: number;
  isViewer: boolean;
}

const MAX_NUMBER_OF_ROIS = 15;

export const stampToolDefault: StampTool = {
  name: 'rectangle',
  disabled: false,
  size: 100,
  minSize: 100,
  maxSize: 10000,
  sizeStep: 100,
  hasInput: true,
};

export function FocusAreaSection({
  regionsOfInterest,
  isAnnotationFeatureActive,
  onVisibilityChange,
  height,
  isViewer,
}: FocusAreaSectionProps): ReactElement {
  const client = useApolloClient();
  const { subProjectId, wsiId } = useParams<keyof SlideRouteParams>();
  const { addSnackbar } = useSnackbarMutations();
  const { activeViewer } = useViewerController();

  const map = activeViewer.map;

  const theme = useTheme();
  const { setRegionsDrawingMode } = useDrawingModeActions();
  const { visibleRegions } = useActiveViewerParams();
  const setActiveViewerParams = useSetActiveViewerParams();
  const { latestRegionSize, setLatestRegionSize } = useDrawingToolSettingsStore(
    (state) => ({
      latestRegionSize: state.latestRegionSize,
      setLatestRegionSize: state.setLatestRegionSize,
    })
  );
  const {
    allROIsMap,
    selectedRegionId,
    setSelectedRegionId,
    updateROISize,
    setSelectedROI,
    getSelectedROI,
    updateROIinDB,
    updateError,
    isSavingUpdate,
    sliderInteraction,
    setSliderInteraction,
  } = useRegionsSettingsStore((state) => ({
    allROIsMap: state.allROIsMap,
    updateROISize: state.updateROISize,
    selectedRegionId: state.selectedRegionId,
    setSelectedRegionId: state.setSelectedRegionId,
    setSelectedROI: state.setSelectedROI,
    getSelectedROI: state.getSelectedROI,
    updateROIinDB: state.updateROIinDB,
    updateError: state.updateError,
    isSavingUpdate: state.isSavingUpdate,
    sliderInteraction: state.sliderInteraction,
    setSliderInteraction: state.setSliderInteraction,
  }));

  const setVisibleRegions = useCallback(
    (updatedVisibleRegions: string[] | undefined) => {
      setActiveViewerParams({ visibleRegions: updatedVisibleRegions });
    },
    [setActiveViewerParams]
  );

  const handleClick = useCallback(() => {
    onVisibilityChange();
    setRegionsDrawingMode(latestRegionSize);
  }, [latestRegionSize, onVisibilityChange, setRegionsDrawingMode]);

  const handleSizeChanged = useCallback(
    (roiId: string, newSize: number) => {
      setSelectedRegionId(roiId);
      setLatestRegionSize(newSize);
      updateROISize(roiId, newSize);
    },
    [setSelectedRegionId, setLatestRegionSize, updateROISize]
  );

  const tooltipText =
    regionsOfInterest.length >= MAX_NUMBER_OF_ROIS
      ? `Only up to ${MAX_NUMBER_OF_ROIS} regions are allowed`
      : '';

  const handleVisibilityChange = (roiId: string, visible: boolean) => {
    if (visible) {
      onVisibilityChange();
      setVisibleRegions(
        visibleRegions === null
          ? nonDeletedROIIds
          : [...compact(visibleRegions), roiId]
      );
    } else {
      setSelectedRegionId(null);
      setVisibleRegions([
        ...compact(visibleRegions?.filter((id) => id !== roiId)),
      ]);
    }
  };

  const resizeRegion = useCallback(
    (size: number) => {
      const selectedROI = getSelectedROI();
      if (!selectedROI || sliderInteraction === 'idle' || isSavingUpdate) {
        return;
      }

      const newROI = resizeFromCenter(selectedROI, size);
      setSelectedROI(newROI);
      setSliderInteraction('idle');

      if (!subProjectId || !wsiId) return;
      updateROIinDB(client, subProjectId, wsiId);
    },
    [
      getSelectedROI,
      sliderInteraction,
      isSavingUpdate,
      setSelectedROI,
      setSliderInteraction,
      subProjectId,
      wsiId,
      updateROIinDB,
      client,
    ]
  );

  const handleOnMouseDown = useCallback(() => {
    setSliderInteraction('resizing');
  }, [setSliderInteraction]);

  const handleOnMouseUp = useCallback(
    (size: number) => {
      resizeRegion(size);
    },
    [resizeRegion]
  );

  useEffect(() => {
    if (updateError) {
      addSnackbar({
        message: updateError?.message,
        type: 'error',
        closesAfter: 10000,
      });
    }
  }, [addSnackbar, updateError]);

  const [regionVisibility, setRegionVisibility] = useState<{
    [key: string]: boolean;
  }>({});

  // Listen to changes on visible regions and update the visibility of the region outside map
  useEffect(() => {
    const newVisibility = regionsOfInterest.reduce<{ [key: string]: boolean }>(
      (acc, roi) => {
        acc[roi.id] =
          isAnnotationFeatureActive && (visibleRegions || []).includes(roi.id);
        return acc;
      },
      {}
    );
    setRegionVisibility(newVisibility);
  }, [visibleRegions, isAnnotationFeatureActive, regionsOfInterest]);

  const nonDeletedROIs = useMemo(
    () => regionsOfInterest.filter((roi) => !roi.deleted),
    [regionsOfInterest]
  );

  const nonDeletedROIIds = useMemo(
    () => nonDeletedROIs.map((roi) => roi.id),
    [nonDeletedROIs]
  );

  const handleNavigationToROI = (regionsId?: string) => {
    if (!regionsId) return;
    setSelectedRegionId(regionsId);
    setRegionVisibility((prev) => ({ ...prev, [regionsId]: true }));
    const newVisibleRegions = [
      ...new Set([...compact(visibleRegions), regionsId]),
    ];
    setVisibleRegions(visibleRegions ? newVisibleRegions : nonDeletedROIIds);
  };

  // TODO refactor into Provider or a hook? Used in annotation navigation too
  const flyToPadding = useMemo(
    () => [
      theme.spacings.button,
      theme.spacings.button,
      theme.spacings.button,
      theme.spacings.button,
    ],
    [theme]
  );

  const navigationROIs = useMemo(() => {
    if (!visibleRegions) return nonDeletedROIs;
    return nonDeletedROIs.filter((roi) => visibleRegions.includes(roi.id));
  }, [nonDeletedROIs, visibleRegions]);

  const buttonContainerStyle = {
    padding: theme.spacings[16],
    height: theme.spacings[44],
    display: 'flex',
    alignItems: 'center',
  };

  const displaySectionsHeading =
    !isViewer || (isViewer && nonDeletedROIs.length > 0);

  return (
    <>
      {displaySectionsHeading ? (
        <Divider color="light">Region of interest</Divider>
      ) : null}
      {regionsOfInterest.map((roi, index) => {
        const isActiveROI = selectedRegionId === roi.id;
        if (!allROIsMap[roi.id] || roi.deleted) return null;
        const { isEditable, latestRegionSize: stampSize } = allROIsMap[roi.id];

        const handleRegionActive = (active: boolean) => {
          if (active) {
            handleVisibilityChange(roi.id, true);
            setSelectedRegionId(roi.id);
            flyToFeatureInspection(
              allROIsMap[roi.id].regionInfo,
              height,
              map,
              flyToPadding
            );
          }
        };

        return (
          <FocusAreaItem
            key={roi.id}
            label={`Region ${index + 1}`}
            active={isActiveROI}
            tool={{ ...stampToolDefault, size: stampSize }}
            isAnnotationFeatureActive={isAnnotationFeatureActive}
            disabled={!isEditable}
            isRegionVisible={regionVisibility[roi.id]}
            onSizeChanged={(newSize) => {
              handleSizeChanged(roi.id, newSize);
            }}
            onVisibilityChange={(visible) => {
              handleVisibilityChange(roi.id, visible);
            }}
            onActiveChange={handleRegionActive}
            onMouseDown={handleOnMouseDown}
            onMouseUp={handleOnMouseUp}
            isViewer={isViewer}
          />
        );
      })}
      {!isViewer ? (
        <Tooltip text={tooltipText}>
          {(tooltipProps) => (
            <div {...tooltipProps}>
              <div style={buttonContainerStyle}>
                <Button
                  small
                  banner
                  aria-label="Add new region"
                  disabled={nonDeletedROIs.length >= MAX_NUMBER_OF_ROIS}
                  variant="primaryOutline"
                  onClick={handleClick}
                >
                  Add new region
                </Button>
              </div>
            </div>
          )}
        </Tooltip>
      ) : null}
      {navigationROIs.length > 0 ? (
        <AnnotationSectionItemsNavigation
          navigationFeatures={isAnnotationFeatureActive ? navigationROIs : []}
          name="Region"
          onNavigation={handleNavigationToROI}
          height={height}
          featureType="focusArea"
        />
      ) : null}
    </>
  );
}
