import { AnnotationCategorySetModule } from '../../../../types';

export const getAnnotationCategorySetModuleChanges = (
  currentModules: AnnotationCategorySetModule[],
  changedModules: AnnotationCategorySetModule[]
): {
  create: AnnotationCategorySetModule[];
  update: AnnotationCategorySetModule[];
  remove: AnnotationCategorySetModule[];
} => {
  const currentModuleIds = currentModules.map(({ moduleId }) => moduleId);
  const changedModulesIds = changedModules.map(({ moduleId }) => moduleId);

  const removeCandidates = currentModules.filter(
    ({ moduleId }) => !changedModulesIds.includes(moduleId)
  );
  const createCandidates = changedModules.filter(
    ({ moduleId }) => !currentModuleIds.includes(moduleId)
  );

  const create = createCandidates.filter(
    ({ moduleName }) =>
      !removeCandidates.some(
        (removedModule) => removedModule.moduleName === moduleName
      )
  );

  const remove = removeCandidates
    .filter(
      ({ moduleName }) =>
        !createCandidates.some(
          (createdModule) => createdModule.moduleName === moduleName
        )
    )
    .map(({ moduleName, moduleId }) => ({ moduleId, moduleName }));

  const update = changedModules
    .filter(
      ({ moduleId, moduleName }) =>
        currentModuleIds.includes(moduleId) &&
        moduleName !==
          currentModules.find(
            (currentModule) => currentModule.moduleId === moduleId
          )?.moduleName
    )
    .map(({ moduleName, moduleId }) => ({ moduleId, moduleName }));

  return {
    create,
    remove,
    update,
  };
};
