import {
  SelectSingle,
  SelectSkeleton,
  TableSubRow,
  TableSubRowCell,
} from '@aignostics/components';
import React, { ReactElement } from 'react';
import { WsiThumbnail } from '../../../../../../components';
import { FluorescenceFile } from '../../../../../../types';
import { OnSetCoefficient } from '../AssignedSlidesTable';
import { getMenuOptions } from '../getRegistrationCoefficientsMenuOptions';
import { $StainingRowRightSide, SelectStyle } from './styles';

export const FluorescenceRow = ({
  fluorescence,
  name,
  onSetCoefficient,
  fluorescenceVisible,
  reference,
  origin,
  availableCoefficients,
  coefficientsLoading,
  rasterTileServerUrl,
  getToken,
}: {
  fluorescence: FluorescenceFile;
  origin: string;
  reference: string;
  subProjectId: string;
  fluorescenceVisible: boolean;
  name: string;
  onSetCoefficient: OnSetCoefficient;
  coefficientsLoading: boolean;
  availableCoefficients: {
    registrationId: string;
    registrationName: string;
    referenceId: string;
  }[];
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
}): ReactElement => {
  const areCoefficientsAvailable = availableCoefficients?.length > 0;

  return (
    <TableSubRow aria-label={`Assigned fluorescence`} key={fluorescence.uuid}>
      <TableSubRowCell>
        <div style={{ width: '48px', height: '48px' }}>
          <WsiThumbnail
            wsiId={fluorescence.channels[0].id}
            getToken={getToken}
            rasterTileServerUrl={rasterTileServerUrl}
          />
        </div>
      </TableSubRowCell>

      <TableSubRowCell
        colSpan={areCoefficientsAvailable ? 6 : 7}
      >{`miF ${name} (${fluorescence.channels.length} channels)`}</TableSubRowCell>
      <TableSubRowCell align="right" colSpan={7}>
        <$StainingRowRightSide data-testid={'mif-registration-dropdown'}>
          {coefficientsLoading && <SelectSkeleton />}
          {areCoefficientsAvailable && (
            <SelectSingle
              key={fluorescence.uuid}
              id={fluorescence.uuid}
              value={fluorescence.registrationId ?? null}
              selectStyle={SelectStyle}
              options={getMenuOptions(
                availableCoefficients,
                fluorescence.registrationId
              )}
              onChange={(v) => {
                if (v?.value) {
                  onSetCoefficient(
                    reference,
                    origin,
                    v.value,
                    fluorescenceVisible,
                    v.label,
                    'Fluorescence'
                  );
                }
              }}
            />
          )}
        </$StainingRowRightSide>
      </TableSubRowCell>
    </TableSubRow>
  );
};
