import {
  Button,
  HStack,
  Loader,
  OnboardingUploadList,
  OnboardingUploadListItemLogoLabel,
  OnboardingUploadListValidationIcon,
  prettyFormatBytes,
  Section,
  UploadModal,
  useDisclosure,
  useSnackbarMutations,
} from '@aignostics/components';
import { pluralize } from '@aignostics/utils';
import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import {
  FORM_ERROR,
  ValidationErrors,
  type FormApi,
  type Mutator,
} from 'final-form';
import arrayMutators, { Mutators } from 'final-form-arrays';
import setFieldTouched from 'final-form-set-field-touched';
import { isEqual as _isEqual } from 'lodash';
import React, { useEffect, useMemo, useState, type ReactElement } from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTheme } from 'styled-components';
import { CSVTemplateDownloadButton } from '../../components/OnboardingBatches/CSVTemplateDownloadButton.component';
import { OnboardingTutorialModal } from '../../components/OnboardingBatches/OnboardingTutorial/OnboardingTutorial.component';
import { Steps } from '../../components/OnboardingBatches/OnboardingTutorial/onboardingTutorial.data';
import useGetBarcodeImageUrl from '../../hooks/useGetBarcodeImageUrl';
import {
  getMatchingWsisSubQueries,
  getMatchingWsisTissueCaseIdAndBlocks,
  useMatchingWsis,
} from '../../hooks/useMatchingWsis';
import useUserAssignedAssociation from '../../hooks/useUserAssignedAssociation';
import { useOnboardingMetricsHook } from '../../providers/sentry/OnboardingMetricsProvider';
import { useSelectedOrganizationUuid } from '../../utils/useSelectedOrganizationUuid';
import { OnboardingCloudFileWithMetadata } from '../TransferCloudSlides/RequestMetadataStep.component';
import { OnboardingLocalFileWithMetadata } from '../UploadSlides/UploadSlidesSteps.component';
import { BatchMetadataFieldset } from './BatchMetadataFieldset';
import {
  DEFAULT_CANCER_SITE_VALUE,
  DEFAULT_DISEASE_VALUE,
  DEFAULT_MORPHOLOGY_VALUE,
  DEFAULT_PREPARATION_TYPE_VALUE,
  DEFAULT_SAMPLE_TYPE_VALUE,
} from './const';
import {
  FileMetadataFieldsetHeader,
  FileMetadataFieldsetList,
} from './FileMetadataFieldset';
import {
  FETCH_ONBOARDING_BATCH_UPDATE_SUGGESTIONS,
  SelectData,
  selectDataQuery,
} from './Form/form.queries';
import {
  convertStateToRecords,
  convertStateToUpdateWsiRecords,
  E_NOT_ALL_FLUORESCENCE_SLIDES_ASSIGNED_PARENTS,
  extractUniqueSlideIndexesFromStainingMismatchErrors,
  generateGroupedOnboardingErrors,
  generateInitialSlides,
  generateMatchingWsisSummary,
  getMultiChannelStainingErrors,
  getSlidesErrors,
  getUniquePatientCaseIdPairs,
  mapSlidesToSlidesWithScannerIds,
} from './Form/form.state';
import type {
  BatchCreateForm,
  BatchCreateFormFilled,
  BatchEditForm,
  MultiChannelFormRow,
  SingleChannelFormRow,
  StainingsMismatchErrors,
} from './Form/form.state.types';
import {
  useCreateOnboardingBatch,
  type CreateOnboardingBatchMutationResult,
  type CreateOnboardingBatchWsiRecord,
} from './hooks/useCreateOnboardingBatch';
import { useFilteredSelectData } from './hooks/useFilteredSelectData';
import {
  UpdateOnboardingBatchMutationResults,
  useUpdateOnboardingBatchWsis,
} from './hooks/useUpdateOnboardingBatchWsis';
import {
  csvParser,
  MULTIPLE_STAININGS_SEPARATOR,
  newColumnMapping,
  type CSVParserInputWRIRow,
} from './parser/parser';
import {
  $Container,
  $FileMetadataFileSetWrapper,
  $Form,
  $HeaderButtonsContainer,
  $SubHeader,
} from './SetFileMetadataStep.styles';
import { getTotalFileSize } from './SetFileMetadataStep.utils';
import { SetFileMetadataStepFooter } from './SetFileMetadataStepFooter';
import StainingsMismatchModal from './StainingsMismatchModal/StainingsMismatchModal.component';

export type OnboardingFileWithMetadata =
  | OnboardingLocalFileWithMetadata
  | OnboardingCloudFileWithMetadata;

interface SetFileMetadataStepProps {
  csv?: File | undefined;
  onSuccessfulCreate?: (args: {
    createOnboardingBatch: CreateOnboardingBatchMutationResult['createOnboardingBatch'];
    records: CreateOnboardingBatchWsiRecord[];
  }) => void;
  onSuccessfulUpdate?: (args: {
    updateOnboardingBatchWsis: UpdateOnboardingBatchMutationResults['updateOnboardingBatchWsis'];
    batch_id: string;
  }) => void;
  submitLabel: 'submit' | 'transfer' | 'update';
  files: OnboardingFileWithMetadata[];
  onboardingBatch?: BatchEditForm;
  authToken: string | null;
  apiUrl: string;
}

const SNACKBAR_TIMEOUT = 8_000;

export function SetFileMetadataStep({
  csv,
  files,
  onSuccessfulCreate,
  onSuccessfulUpdate,
  submitLabel,
  onboardingBatch,
  authToken,
  apiUrl,
}: SetFileMetadataStepProps): ReactElement | null {
  const { endRecordTransferSlidesInteraction } = useOnboardingMetricsHook();
  const theme = useTheme();
  const uploadCsvModal = useDisclosure();
  const stainingsErrorsModal = useDisclosure();
  const organizationUuid = useSelectedOrganizationUuid();
  const { addSnackbar, clearSnackbar } = useSnackbarMutations();

  const [csvProcessed, setCsvProcessed] = useState(false);
  const [csvIsProcessing, setCsvIsProcessing] = useState(false);
  const [selectedCsvFile, setSelectedCsvFile] = useState(csv ?? null);
  const [showExtraFields, setShowExtraFields] = useState(false);
  const [stainingsMismatchErrors, setStainingsMismatchErrors] =
    useState<StainingsMismatchErrors>([]);
  const [updatedStainingsValues, setUpdatedStainingsValues] =
    useState<StainingsMismatchErrors>([]);
  const [stainingsMismatchModalSkip, setStainingMismatchModalSkip] =
    useState<boolean>(false);
  const [
    multiplexSlidesNamesWithVisibleChannels,
    setMultiplexSlidesNamesWithVisibleChannels,
  ] = useState<string[]>([]);

  const addErrorSnackbar = () => {
    addSnackbar({
      message: 'An error has occurred loading stainings and tissues',
      type: 'error',
      closesAfter: SNACKBAR_TIMEOUT,
    });
  };
  const onFormReset = () => {
    clearSnackbar();
    setCsvProcessed(false);
  };

  const selectData = useFilteredSelectData(
    useQuery<SelectData>(selectDataQuery, {
      onError: addErrorSnackbar,
      onCompleted: (data) => {
        if (!data) {
          addErrorSnackbar();
        }
      },
    })
  );

  const {
    data: onboardingBatchesData = {},
    loading: onboardingBatchesLoading,
  } = useQuery(FETCH_ONBOARDING_BATCH_UPDATE_SUGGESTIONS, {
    skip: Boolean(onboardingBatch),
  });
  const { userAssignedAssociation, userAssignedAssociationLoading } =
    useUserAssignedAssociation();
  const createOnboardingBatchMutation = useCreateOnboardingBatch({
    onboardingType: submitLabel === 'submit' ? 'local' : 'remote',
  });
  const updateOnboardingBatchWsisMutation = useUpdateOnboardingBatchWsis(
    onboardingBatch?.batch_id
  );

  const onToggleChannelsClick = (
    isChannelsOpen: boolean,
    slideFileName: string,
    multiplexSlidesNamesWithVisibleChannels: string[]
  ) => {
    const updatedMultiplexSlidesWithVisibleChannels = !isChannelsOpen
      ? [...multiplexSlidesNamesWithVisibleChannels, slideFileName]
      : multiplexSlidesNamesWithVisibleChannels.filter(
          (visibleChannelSlideName) => visibleChannelSlideName !== slideFileName
        );
    setMultiplexSlidesNamesWithVisibleChannels(
      updatedMultiplexSlidesWithVisibleChannels
    );
  };
  const showValidationErrors = (errors: ValidationErrors) => {
    clearSnackbar();
    if (errors?.[FORM_ERROR]) {
      addSnackbar({
        type: 'error',
        message: errors[FORM_ERROR],
        closesAfter: SNACKBAR_TIMEOUT,
      });
    }
    for (const message of generateGroupedOnboardingErrors(errors)) {
      addSnackbar({
        type: 'error',
        message,
        closesAfter: SNACKBAR_TIMEOUT,
      });
    }
  };
  const parseCsv = async (
    csvFile: File,
    data: SelectData,
    slides: Array<SingleChannelFormRow | MultiChannelFormRow>,
    form: FormApi
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    const {
      tissues,
      stainings,
      scanners,
      diseases,
      samplePreparations,
      sampleTypes,
    } = data;
    const csvFormInputPrefixes: string[] = [];
    const wsis: CSVParserInputWRIRow[] = [];
    for (const [slideIndex, slide] of slides.entries()) {
      const slideFile =
        slide.type === 'single-channel' ? slide : slide.parentAssigned;
      csvFormInputPrefixes.push(
        slide.type === 'single-channel'
          ? `slides[${slideIndex}].`
          : `slides[${slideIndex}].parentAssigned.`
      );
      wsis.push({
        type: slide.type,
        Filename:
          slide.type === 'single-channel'
            ? slide.slideFile.filename
            : slide.multichannelSlideFile.slideFile.filename,
        Localization: slideFile.tissue,
        Staining:
          slide.type === 'single-channel'
            ? slide.staining
            : slide.multichannelSlideFile.channels
                .map((channel) => channel.staining)
                .join(MULTIPLE_STAININGS_SEPARATOR),
        scannerId: slideFile.scannerId,
        'Case ID': slideFile.caseId,
        'Patient ID': slideFile.patientExternalId,
        Block: slideFile.block,
        Section: slideFile.section,
        Disease: slideFile.disease ?? DEFAULT_DISEASE_VALUE,
        'Preparation Type':
          slideFile.samplePreparation ?? DEFAULT_PREPARATION_TYPE_VALUE,
        'Sample Type': slideFile.sampleType ?? DEFAULT_SAMPLE_TYPE_VALUE,
        Morphology: slideFile.morphology ?? DEFAULT_MORPHOLOGY_VALUE,
        'Cancer Site': slideFile.cancerSite ?? DEFAULT_CANCER_SITE_VALUE,
        parent_tma_row: slideFile.parentTmaRow,
        parent_tma_col: slideFile.parentTmaCol,
        parent_slide_pos_x: slideFile.parentSlidePosX,
        parent_slide_pos_y: slideFile.parentSlidePosY,
        parent_wsi_uuid: slideFile.parentWsiUuid,
        wsi_uuid: slideFile.wsiUuid,
        case_uuid: slideFile.caseUuid,
      });
    }
    const result = await csvParser(
      csvFile,
      wsis,
      tissues,
      stainings,
      scanners,
      diseases,
      samplePreparations,
      sampleTypes,
      morphologies,
      cancerSites
    );
    clearSnackbar();
    if (result.ok) {
      // eslint-disable-next-line sonarjs/cognitive-complexity
      form.batch(() => {
        for (const [index, resultValue] of result.data.entries()) {
          for (const [prop, value] of Object.entries(resultValue)) {
            let isMultipleChannelValues = false;
            if (slides[index].type === 'multi-channel' && prop === 'Staining') {
              const channelsValues = resultValue[prop]?.split(
                MULTIPLE_STAININGS_SEPARATOR
              );
              if (channelsValues && channelsValues.length > 1) {
                isMultipleChannelValues = true;
                for (
                  let channelIndex = 0;
                  channelIndex <
                  Math.min(
                    (slides[index] as MultiChannelFormRow).multichannelSlideFile
                      .channels.length,
                    channelsValues.length
                  );
                  channelIndex++
                ) {
                  const fieldName =
                    `slides[${index}].multichannelSlideFile.channels[${channelIndex}].staining` as keyof BatchCreateForm;
                  form.change(fieldName, channelsValues[channelIndex]);
                  form.mutators.setFieldTouched(fieldName, true);
                }
              }
            }
            if (!isMultipleChannelValues) {
              const propFieldName = newColumnMapping.get(prop)!;
              const fieldName =
                `${csvFormInputPrefixes[index]}${propFieldName}` as keyof BatchCreateForm;
              form.change(fieldName, value);
              form.mutators.setFieldTouched(fieldName, true);
            }
          }
          if (result.csvLineMapping[index]) {
            form.change(
              `${csvFormInputPrefixes[index]}csvLineMapping`,
              result.csvLineMapping[index]
            );
          }
        }
      });
      result.warnings.forEach((warning) =>
        addSnackbar({
          type: 'warning',
          message: warning,
          closesAfter: SNACKBAR_TIMEOUT,
        })
      );
    } else {
      addSnackbar({
        type: 'error',
        message: result.error,
        closesAfter: SNACKBAR_TIMEOUT,
      });
    }
  };

  useEffect(() => {
    if (
      stainingsMismatchErrors.length &&
      !stainingsMismatchModalSkip &&
      !csvIsProcessing
    ) {
      stainingsErrorsModal.open();
    }
  }, [
    stainingsErrorsModal,
    stainingsMismatchErrors,
    stainingsMismatchModalSkip,
    csvIsProcessing,
  ]);

  useEffect(() => {
    endRecordTransferSlidesInteraction();
  }, [endRecordTransferSlidesInteraction]);

  const initialValues: BatchCreateForm = useMemo(
    () =>
      onboardingBatch
        ? {
            ...onboardingBatch,
            areFieldsSyncing: false,
            slides: mapSlidesToSlidesWithScannerIds(
              onboardingBatch.slides,
              selectData.data?.scanners
            ),
          }
        : {
            batch_name: '',
            association: null,
            species: null,
            areFieldsSyncing: false,
            slides: generateInitialSlides(
              selectData.data?.stainings ?? null,
              files
            ),
          },
    [onboardingBatch, files, selectData]
  );

  const {
    matchingWsisGroups,
    matchingWsisByBlockTissue,
    handleCompletedRefetchMatchingWsisGroups,
    refetchMatchingWsis,
    refetchMatchingWsisLoadingStatus,
  } = useMatchingWsis();

  if (selectData.loading || userAssignedAssociationLoading) {
    return (
      <Section>
        <Loader />
      </Section>
    );
  }

  if (selectData.error || selectData.data === undefined) {
    return null;
  }

  const {
    associations,
    tissues,
    stainings,
    scanners,
    species,
    diseases,
    samplePreparations,
    sampleTypes,
    morphologies,
    cancerSites,
  } = selectData.data;

  const totalFilesSize = getTotalFileSize(files, onboardingBatch);

  return (
    <>
      <FinalForm<BatchCreateForm, BatchCreateForm>
        mutators={{
          ...(arrayMutators as unknown as Record<
            keyof Mutators,
            Mutator<BatchCreateForm, BatchCreateForm>
          >),
          ...{
            setFieldTouched: setFieldTouched as Mutator<
              BatchCreateForm,
              BatchCreateForm
            >,
          },
        }}
        validate={({ slides, areFieldsSyncing }) => {
          const slidesErrors = getSlidesErrors(
            slides,
            matchingWsisGroups,
            matchingWsisByBlockTissue,
            stainings
          );
          if (slidesErrors) {
            const multiChannelStainingErrors = getMultiChannelStainingErrors(
              slidesErrors,
              slides
            );
            if (
              !_isEqual(multiChannelStainingErrors, stainingsMismatchErrors)
            ) {
              setStainingsMismatchErrors(multiChannelStainingErrors);
              setMultiplexSlidesNamesWithVisibleChannels(
                extractUniqueSlideIndexesFromStainingMismatchErrors(
                  multiChannelStainingErrors
                ).map((index) => {
                  const slide = slides[index];

                  return slide.type === 'single-channel'
                    ? slide.slideFile.filename
                    : slide.multichannelSlideFile.slideFile.filename;
                })
              );
              setUpdatedStainingsValues(
                multiChannelStainingErrors.map((error) => ({
                  ...error,
                  value: null,
                }))
              );
            }
          }

          return {
            ...(slidesErrors !== null && {
              slides: slidesErrors,
            }),
            ...(!slides.every(({ type }) =>
              ['single-channel', 'multi-channel'].includes(type)
            ) && {
              [FORM_ERROR]: E_NOT_ALL_FLUORESCENCE_SLIDES_ASSIGNED_PARENTS,
            }),
            ...(areFieldsSyncing && {
              [FORM_ERROR]: 'Values are being updated. Please wait.',
            }),
          };
        }}
        onSubmit={async (values) => {
          if (refetchMatchingWsisLoadingStatus) {
            addSnackbar({
              type: 'error',
              message: 'Validation is in progress, can not submit',
              closesAfter: 3000,
            });
            return;
          }
          const resultValues = values as BatchCreateFormFilled;
          try {
            const batch_id = onboardingBatch?.batch_id;
            if (
              batch_id &&
              updateOnboardingBatchWsisMutation !== null &&
              onSuccessfulUpdate
            ) {
              const { updateOnboardingBatchWsis } =
                await updateOnboardingBatchWsisMutation({
                  batch_id,
                  wsis: convertStateToUpdateWsiRecords(resultValues.slides),
                  association: onboardingBatch.association,
                });
              clearSnackbar();
              onSuccessfulUpdate({
                updateOnboardingBatchWsis,
                batch_id,
              });
            } else if (onSuccessfulCreate) {
              const records = convertStateToRecords(files, resultValues.slides);
              const { createOnboardingBatch } =
                await createOnboardingBatchMutation({
                  batch_name: resultValues.batch_name,
                  species: resultValues.species,
                  association:
                    userAssignedAssociation !== null
                      ? userAssignedAssociation.name
                      : resultValues.association,
                  wsis: records,
                });
              clearSnackbar();
              onSuccessfulCreate({
                createOnboardingBatch,
                records,
              });
            }

            return;
          } catch (error) {
            Sentry.captureException(error, {
              extra: {
                values: resultValues,
              },
            });
            clearSnackbar();
            addSnackbar({
              type: 'error',
              message: String(error),
              closesAfter: SNACKBAR_TIMEOUT,
            });
          }
        }}
        initialValues={initialValues}
        subscription={{ submitting: true }}
        render={({ form, handleSubmit, submitting }) => (
          <>
            <Section background="white" loading={submitting}>
              <HStack justifyContent="space-between">
                <span
                  aria-label="Form Metadata header text"
                  style={{
                    ...theme.fontStyles.displayBold,
                  }}
                >
                  <FormSpy
                    subscription={{ values: true }}
                    render={({ values: { slides } }) =>
                      `${slides.length ?? 0} ${pluralize(
                        'slide',
                        slides.length ?? 0
                      )} - Total size: ${prettyFormatBytes(totalFilesSize)}`
                    }
                  />
                </span>
                <$HeaderButtonsContainer>
                  <CSVTemplateDownloadButton />
                  {!selectedCsvFile ? (
                    <Button onClick={uploadCsvModal.open} small>
                      Add CSV File
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        setSelectedCsvFile(null);
                        onFormReset();
                        form.reset();
                      }}
                      small
                    >{`Reset form & remove "${selectedCsvFile.name}"`}</Button>
                  )}
                </$HeaderButtonsContainer>
              </HStack>
            </Section>
            <Section
              aria-label="SetFileMetadataStep Section"
              innerDontConstrainWidth
              style={{ paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }}
              innerStyle={{
                flexGrow: 1,
              }}
            >
              <$Form onSubmit={handleSubmit} noValidate>
                {/*
                 * without this button first Button click in the form will be triggered
                 * which is currently ToggleExtraFields
                 * see: https://stackoverflow.com/a/51507806
                 */}
                <button type="submit" disabled style={{ display: 'none' }} />
                <$Container>
                  <div style={{ width: theme.breakpoints.FULL }}>
                    <$SubHeader>
                      <span>*Mandatory data</span>
                    </$SubHeader>
                    <FormSpy
                      subscription={{ values: true }}
                      render={({ values, form }) => (
                        <BatchMetadataFieldset
                          isDisabled={Boolean(onboardingBatch)}
                          availableBatches={
                            onboardingBatchesData.onboardingBatchNames ?? []
                          }
                          availableAssociations={associations}
                          userAssignedAssociation={userAssignedAssociation}
                          availableSpecies={species}
                          isLoading={
                            onboardingBatchesLoading ||
                            selectData.loading ||
                            userAssignedAssociationLoading
                          }
                          formSlides={values.slides}
                          formBatchName={values.batchName}
                          formChange={form.change}
                          initialState={initialValues}
                        />
                      )}
                    />
                  </div>
                  <$FileMetadataFileSetWrapper>
                    <OnboardingUploadList
                      tableHeader={
                        <FileMetadataFieldsetHeader
                          scanners={scanners}
                          stainings={stainings}
                          tissues={tissues}
                          diseases={diseases}
                          preparationTypes={samplePreparations}
                          sampleTypes={sampleTypes}
                          morphologies={morphologies}
                          cancerSites={cancerSites}
                          showExtraFields={showExtraFields}
                          formApi={form}
                          onToggleExtraFieldsClick={() => {
                            setShowExtraFields(!showExtraFields);
                          }}
                        />
                      }
                      fixedColumn={
                        <FieldArray<SingleChannelFormRow | MultiChannelFormRow>
                          name="slides"
                          render={({ fields, meta }) =>
                            fields.map((_, index) => {
                              const slide = fields.value[index];
                              const slideData =
                                slide.type === 'single-channel'
                                  ? slide
                                  : slide.parentAssigned;
                              const slideFile =
                                slide.type === 'single-channel'
                                  ? slide.slideFile
                                  : slide.multichannelSlideFile.slideFile;

                              return (
                                <OnboardingUploadListItemLogoLabel
                                  key={`${slideFile.fileIndex}_${slideFile.filename}`}
                                  title={slideFile.filename}
                                  subtitle={
                                    slideData.csvLineMapping
                                      ? `CSV line ${slideData.csvLineMapping}`
                                      : undefined
                                  }
                                  barcodeRequestHook={useGetBarcodeImageUrl}
                                  slideFileSource={slideFile.source}
                                  organizationUuid={organizationUuid}
                                  apiUrl={apiUrl}
                                  token={authToken || ''}
                                  icon={
                                    <OnboardingUploadListValidationIcon
                                      isValid={!meta.error?.[index]}
                                    />
                                  }
                                  isExpanded={multiplexSlidesNamesWithVisibleChannels.includes(
                                    slideFile.filename
                                  )}
                                  isMultiplex={slide.type !== 'single-channel'}
                                  totalChannels={
                                    slide.type !== 'single-channel'
                                      ? slide.multichannelSlideFile.channels
                                          .length
                                      : 0
                                  }
                                  relatedWsisCountSummary={generateMatchingWsisSummary(
                                    slide,
                                    matchingWsisGroups
                                  )}
                                />
                              );
                            })
                          }
                        />
                      }
                      showExtraFields={showExtraFields}
                    >
                      <FileMetadataFieldsetList
                        scanners={scanners}
                        stainings={stainings}
                        tissues={tissues}
                        diseases={diseases}
                        preparationTypes={samplePreparations}
                        sampleTypes={sampleTypes}
                        morphologies={morphologies}
                        cancerSites={cancerSites}
                        openStainingsErrorsModal={stainingsErrorsModal.open}
                        showSlideRemoveButton={!onboardingBatch}
                        showExtraFields={showExtraFields}
                        onToggleChannelsClick={onToggleChannelsClick}
                        matchingWsisGroups={matchingWsisGroups}
                        multiplexSlidesNamesWithVisibleChannels={
                          multiplexSlidesNamesWithVisibleChannels
                        }
                      />
                    </OnboardingUploadList>
                  </$FileMetadataFileSetWrapper>
                </$Container>
                <SetFileMetadataStepFooter
                  onReset={() => {
                    onFormReset();
                    form.restart();
                  }}
                  onSubmit={showValidationErrors}
                />
                <FormSpy
                  subscription={{ values: true }}
                  onChange={({ values: { slides, association } }) => {
                    const associationParam =
                      association ?? userAssignedAssociation?.name;
                    const matchingWsisTissueCaseIdAndBlocks =
                      getMatchingWsisTissueCaseIdAndBlocks(slides);

                    if (
                      (getUniquePatientCaseIdPairs(slides).length !== 0 ||
                        matchingWsisTissueCaseIdAndBlocks.length !== 0) &&
                      Boolean(associationParam)
                    ) {
                      void refetchMatchingWsis({
                        variables: {
                          association: associationParam,
                          subqueries: getMatchingWsisSubQueries(
                            slides,
                            associationParam
                          ),
                          blockCaseIdTissuesTriples:
                            getMatchingWsisTissueCaseIdAndBlocks(slides),
                        },
                        onCompleted(data) {
                          handleCompletedRefetchMatchingWsisGroups(
                            slides,
                            data
                          );
                        },
                      });
                    }
                  }}
                  render={() => null} // need this line just for avoiding error messages in unit tests
                />
                <FormSpy
                  subscription={{ values: true }}
                  render={({ values: { slides }, form }) => {
                    /**
                       1. Using render instead of onChange because it requires access to the FormAPI.
                       2. This is triggered not only by changes in values but also by changes in selectData, selectedCsvFile,
                       and even when the uploadCsvModal opens or closes.
                       Thus, for this component, it functions similarly to useEffect, but also provides access to the FormAPI and the current values of slides.
                     */
                    if (
                      selectedCsvFile &&
                      !csvProcessed &&
                      selectData.data !== undefined
                    ) {
                      setCsvIsProcessing(true);
                      void parseCsv(
                        selectedCsvFile,
                        selectData.data,
                        slides,
                        form
                      );
                      setCsvIsProcessing(false);
                      setCsvProcessed(true);
                    }
                    return null;
                  }}
                />
                <StainingsMismatchModal
                  isOpen={stainingsErrorsModal.isOpen}
                  onCloseModal={() => {
                    setStainingMismatchModalSkip(true);
                    stainingsErrorsModal.close();
                  }}
                  stainingsMismatchErrors={stainingsMismatchErrors}
                  updatedStainingsValues={updatedStainingsValues}
                  stainings={stainings}
                  changeField={form.change}
                  setFieldTouched={form.mutators.setFieldTouched}
                  setUpdatedStainingsValues={setUpdatedStainingsValues}
                />
              </$Form>
            </Section>
          </>
        )}
      />
      <UploadModal
        isVisible={uploadCsvModal.isOpen}
        accept=".csv"
        acceptMultipleFiles={false}
        onClose={uploadCsvModal.close}
        onDropAccepted={([uploadedCsv]) => {
          setSelectedCsvFile(uploadedCsv);
          uploadCsvModal.close();
          setStainingMismatchModalSkip(false);
        }}
        title="Add your CSV document"
      />
      <OnboardingTutorialModal initialStep={Steps.METADATA} />
    </>
  );
}
