import { Button, HStack, Icon, useDisclosure } from '@aignostics/components';
import React, { ReactElement } from 'react';
import { AnnotationCategorySet } from '../../../types';
import { DeleteAnnotationCategorySetModal } from './DeleteAnnotationCategorySetModal/DeleteAnnotationCategorySetModal.component';
import { EditAnnotationCategorySetModal } from './EditAnnotationCategrySetModal/EditAnnotationCategorySetModal.component';

export const AnnotationCategorySetsTableRowActions = ({
  annotationCategorySet,
  onUpdateAnnotationCategorySet,
  annotationCategorySetCodes,
  annotationCategorySetNames,
}: {
  annotationCategorySet: AnnotationCategorySet;
  onUpdateAnnotationCategorySet: () => void;
  annotationCategorySetCodes: string[];
  annotationCategorySetNames: string[];
}): ReactElement => {
  const deleteAnnotationCategorySetDisclosure = useDisclosure();
  const { id, categoryCount, name } = annotationCategorySet;
  const editAnnotationCategorySetDisclosure = useDisclosure();
  return (
    <HStack spacing="16">
      <Button
        small
        variant="ghost"
        onClick={editAnnotationCategorySetDisclosure.open}
      >
        <Icon icon="Edit" />
      </Button>
      <Button
        disabled={categoryCount !== undefined && categoryCount > 0}
        onClick={deleteAnnotationCategorySetDisclosure.open}
        small
        variant="ghost"
        aria-label={`delete-annotation-category-set-${name}`}
      >
        <Icon icon="Trash" />
      </Button>
      {deleteAnnotationCategorySetDisclosure.isOpen ? (
        <DeleteAnnotationCategorySetModal
          onDeleteAnnotationCategorySet={() => {
            onUpdateAnnotationCategorySet();
            deleteAnnotationCategorySetDisclosure.close();
          }}
          onClose={deleteAnnotationCategorySetDisclosure.close}
          annotationCategorySetId={id}
        />
      ) : null}
      {editAnnotationCategorySetDisclosure.isOpen ? (
        <EditAnnotationCategorySetModal
          annotationCategorySet={annotationCategorySet}
          annotationCategorySetCodes={annotationCategorySetCodes}
          annotationCategorySetNames={annotationCategorySetNames}
          isVisible={editAnnotationCategorySetDisclosure.isOpen}
          onClose={editAnnotationCategorySetDisclosure.close}
          onUpdateAnnotationCategorySet={() => {
            onUpdateAnnotationCategorySet();
            editAnnotationCategorySetDisclosure.close();
          }}
        />
      ) : null}
    </HStack>
  );
};
