import {
  Button,
  CircularProgress,
  HStack,
  Modal,
  Section,
  VStack,
  useSnackbarMutations,
} from '@aignostics/components';
import { useMutation, useQuery } from '@apollo/client';
import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';
import { FETCH_CAN_DELETE_ANNOTATION_CATEGORY } from '../FETCH_CAN_DELETE_ANNOTATION_CATEGORY';
import { DELETE_ANNOTATION_CATEGORY } from './DELETE_ANNOTATION_CATEGORY';
import {
  $ModalContent,
  $ModalHeader,
} from './DeleteAnnotationCategoryModal.component.styles';

export const ANNOTATION_CATEGORY_CAN_BE_DELETED =
  'This category is not assigned to a subproject and has no attached annotations.';
export const ANNOTATION_CATEGORY_IS_ASSIGNED_CAN_BE_DELETED =
  'No annotations are created for this category, but it is assigned to at least one subproject. If you confirm, we will unassign the category from all affected subprojects and proceed with deletion.';
export type DeleteAnnotationCategoryModalProps = {
  annotationCategoryId: string;
  onClose: () => void;
  onDeleteAnnotationCategory: () => void;
};
export const DeleteAnnotationCategoryModal = ({
  annotationCategoryId,
  onClose,
  onDeleteAnnotationCategory,
}: DeleteAnnotationCategoryModalProps): ReactElement => {
  const theme = useTheme();
  const { addSnackbar } = useSnackbarMutations();

  const { data, loading } = useQuery<{
    canDeleteAnnotationCategory: {
      canDelete: boolean;
      hasAnnotation: boolean;
      isAssignedToSubproject: boolean;
    };
  }>(FETCH_CAN_DELETE_ANNOTATION_CATEGORY, {
    variables: {
      annotationCategoryId,
    },
  });

  const [
    deleteAnnotationCategory,
    { loading: deleteAnnotationCategoryLoading },
  ] = useMutation<{
    deleteAnnotationCategory: string;
  }>(DELETE_ANNOTATION_CATEGORY, {
    onError: (error) => {
      addSnackbar({
        type: 'error',
        message: error.message,
      });
    },
    onCompleted: (data) => {
      addSnackbar({
        type: 'success',
        message: data.deleteAnnotationCategory,
        closesAfter: 1000,
      });
      onDeleteAnnotationCategory();
    },
  });

  const canDeleteAnnotationCategory =
    data?.canDeleteAnnotationCategory?.canDelete;
  const isAssignedToSubproject =
    data?.canDeleteAnnotationCategory.isAssignedToSubproject;

  return (
    <Modal onClose={onClose} isVisible={true} size="small">
      <Section
        loading={deleteAnnotationCategoryLoading}
        style={{ padding: `${theme.spacings[32]}px` }}
      >
        {loading ? (
          <VStack spacing="24" alignItems="center">
            <CircularProgress inditerminate size="large" />
            <VStack spacing="16" style={{ width: '100%' }}>
              <$ModalHeader>Checking for annotations</$ModalHeader>
              <$ModalContent>
                Only categories without annotations can be deleted.
              </$ModalContent>
            </VStack>
          </VStack>
        ) : null}
        {!loading && canDeleteAnnotationCategory ? (
          <VStack spacing="32">
            <VStack style={{ width: '100%' }} spacing="16">
              <$ModalHeader>Delete this Category?</$ModalHeader>
              <$ModalContent>
                {isAssignedToSubproject
                  ? ANNOTATION_CATEGORY_IS_ASSIGNED_CAN_BE_DELETED
                  : ANNOTATION_CATEGORY_CAN_BE_DELETED}
              </$ModalContent>
            </VStack>
            <HStack
              style={{ width: '100%' }}
              spacing="16"
              justifyContent="center"
            >
              <Button
                onClick={async () => {
                  await deleteAnnotationCategory({
                    variables: {
                      annotationCategoryId,
                    },
                  });
                }}
                variant="primary"
              >
                Delete
              </Button>
              <Button onClick={onClose} variant="primaryOutline">
                Cancel
              </Button>
            </HStack>
          </VStack>
        ) : null}
        {!loading && !canDeleteAnnotationCategory ? (
          <VStack spacing="32">
            <VStack spacing="16" style={{ width: '100%' }}>
              <$ModalHeader>This category cannot be deleted</$ModalHeader>
              <$ModalContent>
                This category contains annotations and cannot be deleted.
              </$ModalContent>
            </VStack>
            <HStack justifyContent="center" style={{ width: '100%' }}>
              <Button onClick={onClose} variant="primary">
                Close
              </Button>
            </HStack>
          </VStack>
        ) : null}
      </Section>
    </Modal>
  );
};
