import {
  IconButton,
  PopUp,
  useDisclosure,
  useSnackbarMutations,
} from '@aignostics/components';
import { Map as OLMap, Overlay } from 'ol';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

const $ContextMenu = styled.div<{ isViewer: boolean }>`
  width: ${({ isViewer }) => (isViewer ? '44px' : '88px')};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 2px;
`;

interface ContextMenuProps {
  map: OLMap;
  position: number[];
  isEditable: boolean;
  onDelete: () => void;
  isViewer: boolean;
}

const ContextMenu = ({
  map,
  position,
  isEditable,
  onDelete,
  isViewer,
}: ContextMenuProps): ReactElement => {
  const deleteFocusAreaDialog = useDisclosure();

  const { overlay, element } = useMemo(() => {
    const element = document.createElement('div');
    const overlay = new Overlay({ element });
    return { element, overlay };
  }, []);

  useEffect(() => {
    map.addOverlay(overlay);

    return () => {
      map.removeOverlay(overlay);
    };
  }, [map, overlay]);

  useEffect(() => {
    overlay.setPosition(position);
    overlay.setPositioning('top-center');
  }, [map, overlay, position]);

  const { addSnackbar } = useSnackbarMutations();

  return createPortal(
    <$ContextMenu isViewer={isViewer}>
      <IconButton
        icon="Link"
        onClick={async () => {
          await navigator.clipboard.writeText(window.location.href);
          addSnackbar({
            message: 'Copied link to Region of Interest to clipboard',
            type: 'success',
            closesAfter: 3000,
          });
        }}
        description="Copy link to Region of Interest"
      />
      {!isViewer && (
        <IconButton
          icon="Trash2"
          onClick={() => {
            if (!isEditable) {
              deleteFocusAreaDialog.open();
            } else onDelete();
          }}
          description="Delete Region of Interest"
        />
      )}
      <PopUp
        isVisible={deleteFocusAreaDialog.isOpen}
        title={`Do you want to delete this region of interest it currently contains annotations?`}
        onClose={() => {
          deleteFocusAreaDialog.close();
        }}
        shouldCloseOnBackdropClick={true}
        secondaryAction={{
          label: 'Cancel',
          onClick: () => {
            deleteFocusAreaDialog.close();
          },
        }}
        primaryAction={{
          label: 'Delete',
          onClick: () => {
            onDelete();
            deleteFocusAreaDialog.close();
          },
        }}
      >
        <p style={{ paddingBottom: '6px' }}>
          This action will only delete the region of interest. Annotations
          inside the Region will not be affected.
        </p>
        <p style={{ margin: '0' }}>This action cannot be undone.</p>
      </PopUp>
    </$ContextMenu>,
    element
  );
};

export default ContextMenu;
