import {
  Button,
  Disclosure,
  Icon,
  IconButton,
  Link,
  OneLiner,
  TableCell,
  TableRow,
} from '@aignostics/components';
import { useToggle } from '@aignostics/hooks';
import { useLazyQuery } from '@apollo/client';
import { unionBy } from 'lodash';
import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { WsiThumbnail } from '../../../../../../components';
import { Wsi } from '../../../../../../types';
import { OnSetCoefficient } from '../AssignedSlidesTable';
import { GET_AVAILABLE_COEFFICIENTS } from '../GET_AVAILABLE_COEFFICIENTS';
import { FluorescenceRow } from './FluorescenceRow';
import { StainingRow } from './StainingRow';

const groupArrayBy = <T extends { [key: string]: string }>(
  array: T[],
  key: string
): Record<string, T[]> => {
  return array.reduce<Record<string, T[]>>(function (r, a) {
    r[a[key]] = r[a[key]] || [];
    r[a[key]].push(a);
    return r;
  }, Object.create({}));
};

export const WsiRow = ({
  wsi,
  subprojectId,
  onSetCoefficient,
  infoModalDisclosure,
  showMore,
  to,
  sortOrder,
  rasterTileServerUrl,
  getToken,
}: {
  subprojectId: string;
  wsi: Wsi;
  onSetCoefficient: OnSetCoefficient;
  infoModalDisclosure: Disclosure<Wsi>;
  showMore: boolean;
  to: string;
  sortOrder?: number;
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
}): ReactElement => {
  const location = useLocation();

  const [isExpanded, handleToggleStainingsClick] = useToggle(false);
  const hasActiveFluorescence =
    !wsi.isBlackLayer &&
    wsi.fluorescence?.files?.some((fluorecense) => fluorecense.isVisible);

  const hasActiveStainings =
    wsi.stainings !== undefined && wsi.brightfieldCount > 1;

  const [getCoefficients, { data, loading }] = useLazyQuery<{
    availableCoefficients: {
      stainings: {
        registrationId: string;
        registrationName: string;
        referenceId: string;
      }[];
      fluorescence: {
        registrationId: string;
        registrationName: string;
        referenceId: string;
      }[];
    };
  }>(GET_AVAILABLE_COEFFICIENTS);

  const stainingCoefficients =
    data?.availableCoefficients.stainings &&
    groupArrayBy(data?.availableCoefficients.stainings, 'referenceId');

  const multiplexCoefficients =
    data?.availableCoefficients.fluorescence &&
    groupArrayBy(
      unionBy(data?.availableCoefficients.fluorescence, 'registrationId'),
      'referenceId'
    );

  return (
    <React.Fragment key={wsi.id}>
      <tr style={{ height: '8px' }} />

      <TableRow
        aria-label={`Assigned ${sortOrder ? `#${sortOrder}` : ''} ${wsi.id}`}
      >
        <TableCell tooltipText={wsi.id}>
          <div style={{ width: '48px', height: '48px' }}>
            <WsiThumbnail
              wsiId={wsi.id}
              getToken={getToken}
              rasterTileServerUrl={rasterTileServerUrl}
            />
          </div>
        </TableCell>
        <TableCell
          tooltipText={wsi.name}
          style={{ maxWidth: '25ch', minWidth: '25ch' }}
        >
          <Link href={`${to}${location.search}`} target="_blank">
            <OneLiner>{wsi.name}</OneLiner>
          </Link>
        </TableCell>
        <TableCell align="center">
          <IconButton
            description="Open slide metadata"
            icon="FileText"
            size="button"
            onClick={() => {
              infoModalDisclosure.open(wsi);
            }}
          />
        </TableCell>
        <TableCell align="center">
          {wsi.hasFocusArea ? <Icon icon="Check" /> : null}
        </TableCell>
        <TableCell align="center">{wsi.brightfieldCount}</TableCell>
        <TableCell align="center">{wsi.fluorescenceCount}</TableCell>
        <TableCell align="center">{wsi.annotationsCount}</TableCell>
        <TableCell align="center">{wsi.taggersCount}</TableCell>
        <TableCell align="center">{wsi.overlaysCount}</TableCell>
        {showMore && (
          <TableCell>
            {(hasActiveStainings || hasActiveFluorescence) && (
              <Button
                small
                variant="ghost"
                onClick={() => {
                  void getCoefficients({
                    variables: { subProjectId: subprojectId, wsiId: wsi.id },
                  });
                  handleToggleStainingsClick();
                }}
              >
                {`${isExpanded ? 'Hide' : 'Show'} Stainings`}
              </Button>
            )}
          </TableCell>
        )}
      </TableRow>
      {isExpanded && (
        <>
          <>
            {hasActiveStainings &&
              wsi.stainings?.map((staining) => {
                if (!staining.isActive) return;
                return (
                  <StainingRow
                    stainingLayer={staining}
                    key={staining.id}
                    subProjectId={subprojectId}
                    reference={wsi.id}
                    onSetCoefficient={onSetCoefficient}
                    coefficientsLoading={loading}
                    availableCoefficients={
                      stainingCoefficients?.[staining.id] ?? []
                    }
                    rasterTileServerUrl={rasterTileServerUrl}
                    getToken={getToken}
                  />
                );
              })}
          </>
          <>
            {hasActiveFluorescence &&
              wsi.fluorescence.files.map((group) => {
                const origin = group.uuid;
                const isFluorescenceVisible = group.isVisible;

                if (!isFluorescenceVisible) return;
                return (
                  <FluorescenceRow
                    fluorescence={group}
                    subProjectId={subprojectId}
                    reference={wsi.id}
                    origin={origin}
                    fluorescenceVisible={isFluorescenceVisible}
                    coefficientsLoading={loading}
                    key="fluorescence row"
                    // used for backward compatibility
                    name={group.wsiId ?? wsi.name}
                    onSetCoefficient={onSetCoefficient}
                    availableCoefficients={
                      multiplexCoefficients?.[group.uuid] ?? []
                    }
                    rasterTileServerUrl={rasterTileServerUrl}
                    getToken={getToken}
                  />
                );
              })}
          </>
        </>
      )}
    </React.Fragment>
  );
};
